import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';
import './styles.css';
import logo from '../../assets/logo.svg';

export default class Contact extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    if (!this.props.content) return null;

    return (
      <div className={'footer'}>
        <div className={'circle-wrapper'}>
          <div className={'circle-blue'} />
        </div>
        <Container>
          <Row>
            <Col>
              {this.props.content.logo &&
                this.props.content.logo.sizes && (
                  <div className={'logo'}>
                    <img src={this.props.content.logo.sizes.medium} />
                  </div>
                )}
              <div
                className={'content'}
                dangerouslySetInnerHTML={{
                  __html: this.props.content.fist_block,
                }}
              />
            </Col>
            <Col>
              <div
                className={'content'}
                dangerouslySetInnerHTML={{
                  __html: this.props.content.second_block,
                }}
              />
            </Col>
            <Col>
              <div
                className={'content'}
                dangerouslySetInnerHTML={{
                  __html: this.props.content.third_block,
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col xs="12" md="12">
              <div className={'wrapper'}>
                © {new Date().getFullYear()} Todos os direitos reservados,
                developed in collaboration with{' '}
                <a href="https://cors.digital/"> CORS Digital</a>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}
