import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import ScrollAnimation from 'react-animate-on-scroll';
import './styles.css';
import Title from '../Title';

const Show = props => {
  return <React.Fragment>{props.children}</React.Fragment>;
};

function LayoutLogoRow(props) {
  if (!props.content) return null;
  return (
    <div className={`logo-row ${props.first ? 'first' : ''}`}>
      <Container>
        {props.content.map((row, i) => {
          return (
            <Show key={i}>
              {row && (
                <div className={'content-wrap'}>
                  {row.logo && (
                    <div className={'image'}>
                      <img
                        src={row.logo.url && row.logo.url}
                        alt={row.logo.alt}
                      />
                    </div>
                  )}
                </div>
              )}
            </Show>
          );
        })}
      </Container>
    </div>
  );
}

export default LayoutLogoRow;
