import * as constants from './constants';
import config from '../../../config';
import isSupported from '../../utils/localStorage';

let initialState = {
  topmenu: [],
  submenu: [],
};

const isLocalStorage = isSupported();
let newState = {};
//lets load user data for reload
if (isLocalStorage && localStorage.menus) {
  initialState = JSON.parse(localStorage.menus);
}
export default (state = initialState, action) => {
  switch (action.type) {
    case constants.SET_CURRENT_MENUS:
      const regexWebsite = new RegExp(config.websiteURL);
      newState = {
        ...state,
        topmenu:
          action.menus.topmenu.items &&
          action.menus.topmenu.items.map(menu => {
            return {
              link: menu.url.replace(regexWebsite, ''),
              title: menu.title,
            };
          }),
      };
      if (isLocalStorage) localStorage.menus = JSON.stringify(newState);
      return newState;
    default:
      return state;
  }
};
