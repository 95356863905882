// The basics
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router';
import { frontloadConnect } from 'react-frontload';
import LoadingBar from 'react-redux-loading-bar';

// Action creators and helpers
// import { establishCurrentUser } from '../modules/auth';
import { getMenus } from './containers/Menus/actions';
import { getOptions } from './containers/Options/actions';
import { isServer } from '../store';
import { setLanguage } from './containers/Language/actions';

// import Header from './header';
import Header from './components/Header';
import Footer from './components/Footer';
import Routes from './routes';

import config from '../config';

import 'normalize.css/normalize.css';
import 'animate.css/animate.min.css';
import 'simple-line-icons/css/simple-line-icons.css';
import 'bootstrap/dist/css/bootstrap.css';
import 'slick-carousel/slick/slick.css';

import './app.css';

const frontload = async props => {
  return Promise.all([
    props.setLanguage(props.match.params.lang),
    props.getMenus(props.match.params.lang),
    props.getOptions(props.match.params.lang),
  ]);
};

class App extends Component {
  constructor(props) {
    super(props);

    if (typeof window !== 'undefined') {
      var browserUpdate = require('browser-update');

      browserUpdate({
        required: {
          e: -2,
          i: 11,
          f: -3,
          o: -3,
          s: 10.1,
          c: '64.0.3282.16817',
          samsung: 7.0,
          vivaldi: 1.2,
        },
        insecure: true,
      });
    }
  }
  componentWillMount() {
    // this.props.getMenus();
    // if (!isServer) {
    //   this.props.establishCurrentUser();
    // }
  }

  isthishomepage() {
    if (
      this.props.location.pathname === '/' ||
      this.props.location.pathname === '/index.html'
    )
      return true;
    return config.languages.find(lang => {
      return (
        this.props.location.pathname === `/${lang}` ||
        this.props.location.pathname === `/${lang}/`
      );
    });
  }

  render() {
    const contentLength =
      (this.props.contentPage &&
        this.props.contentPage.acf &&
        this.props.contentPage.acf.content &&
        this.props.contentPage.acf.content.length) ||
      0;
    return (
      <div id="app">
        <LoadingBar style={{ zIndex: 999, backgroundColor: '#489ed8' }} />
        <Header
          isAuthenticated={this.props.isAuthenticated}
          options={this.props.options}
          current={this.props.location.pathname}
          isHome={this.isthishomepage()}
          lang={this.props.match.params.lang}
        />
        <div id="content">
          <Routes />
        </div>
        <Footer
          content={this.props.content}
          current={this.props.location.pathname}
          isHome={this.isthishomepage()}
          lang={this.props.match.params.lang}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated,
  content: state.options.options.content,
  options: state.options.options.options,
  contentPage: state.content.currentContent,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ getMenus, getOptions, setLanguage }, dispatch);

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(
    frontloadConnect(frontload, {
      onMount: true,
      onUpdate: false,
    })(App)
  )
);
