import React from 'react';
import { Route, Switch } from 'react-router-dom';
import config from '../../config';

import Loadable from 'react-loadable';
import NotFound from './not-found';

const Content = Loadable({
  loader: () => import(/* webpackChunkName: "content" */ './content'),
  loading: () => null,
  modules: ['content'],
});

const Dashboard = Loadable({
  loader: () => import(/* webpackChunkName: "dashboard" */ './dashboard'),
  loading: () => null,
  modules: ['dashboard'],
});

const languages = config.languages.join('|');

export default () => (
  <Switch>
    <Route exact path={`/:lang(${languages})?/NotFound`} component={NotFound} />

    <Route
      path={`/:lang(${languages})?/:type/types/:category`}
      component={Content}
    />

    <Route path={`/:lang(${languages})?/:type/:content`} component={Content} />

    <Route exact path={`/:lang(${languages})?/:content?`} component={Content} />
    <Route exact path={`*`} component={NotFound} />
  </Switch>
);
